import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import myImg from "../../Assets/logo-img.jpg";
import Tilt from "react-parallax-tilt";
import {
  AiFillGithub,
  AiOutlineTwitter,
  AiFillInstagram,
} from "react-icons/ai";
import { FaLinkedinIn } from "react-icons/fa";

function Home2() {
  return (
    <Container fluid className="home-about-section" id="about">
      <Container>
        <Row>
          <Col md={8} className="home-about-description">
            <h1 style={{ fontSize: "2.6em" }}>
              LET ME <span className="purple"> INTRODUCE </span> MYSELF
            </h1>
            <p className="home-about-body">
            Software Developer who enjoys learning and sharing knowledge. Based in Italy 🇮🇹
              <br />
              <br />I am proficient in classic programming languages such as
              <i>
                <b className="purple"> Python, JavaScript, Java</b>
              </i>
              <br />
              <br />
              My fields of interest include building new &nbsp;
              <i>
                <b className="purple">Web Technologies and Products </b> as well as cloud computing with  {" "}
                <b className="purple">
                AWS.
                </b>
              </i>
              <br />
              <br />
            I also apply my passion for developing products
              with <b className="purple">Node.js</b> and
              <i>
                <b className="purple">
                  {" "}
                  Modern Javascript Library and Frameworks
                </b>
              </i>
              &nbsp; like
              <i>
                <b className="purple"> React.js and Next.js</b>
              </i>
            </p>
          </Col>
          <Col md={4} className="myAvtar">
          <Tilt>
  <img
    src={myImg}
    className="img-fluid"
    alt="avatar"
    style={{
      borderRadius: '50%',
      width: '240px',  // Adjust the size as needed
      height: '240px', // Adjust the size as needed
      objectFit: 'cover', // Ensures the image covers the entire circular area
      boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)' // Add shadow with desired parameters
    }}
  />
</Tilt>

          </Col>
        </Row>
        <Row>
          <Col md={12} className="home-about-social">
            <h1>FIND ME ON</h1>
            <p>
            Feel free to <span className="purple">connect </span>with me
            </p>
            <ul className="home-about-social-links">
              <li className="social-icons">
                <a
                  href="https://github.com/Gediontkd"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <AiFillGithub />
                </a>
              </li>
              {/* <li className="social-icons">
                <a
                  href="https://twitter.com/Gedion_GT"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <AiOutlineTwitter />
                </a>
              </li> */}
              <li className="social-icons">
                <a
                  href="https://www.linkedin.com/in/gedion-daniel-760ba6280/"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <FaLinkedinIn />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://www.instagram.com/gediondev/"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour home-social-icons"
                >
                  <AiFillInstagram />
                </a>
              </li>
            </ul>
            <p style={{ fontWeight: 'bold', fontSize: '1.5rem' }}>
  Email : 
  <a href="mailto:info@gediondaniel.dev" style={{ color: 'inherit', textDecoration: 'none', marginLeft: '0.5rem' }}>
    info@gediondaniel.dev
  </a>
</p>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}
export default Home2;
